import { useState,  useLayoutEffect, useEffect } from "react";
import { CreatePrimaryToken, FetchIndividual, GetSecuredContents, getInfo, CreateSecondaryToken, GetTemplatesByServiceProviderID, GetTemplateByID } from "../utilities/calls";
import { useNavigate, Link, useParams,useLocation } from "react-router-dom";
import { AiOutlineImport, AiOutlineProfile } from "react-icons/ai";
import { ProcessTemplate } from "../utilities/templates";

export default function ContactSP() {
    let { state } = useLocation();

    const [individual, setIndividual] = useState({});
    const [addrList, setAddrList] = useState([]);
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const text = state?.text || "";
    const isBen = state?.isBen || false;
    const [contenttype, setContenttype] = useState(state?.contenttype || "");
    const [securedContents, setSecuredContents] = useState([]);
    const [addressSelected, setAddressSelected] = useState();
    const [selectedContentId, setSelectedContentId] = useState();
    const { id } = useParams();
    const navigate = useNavigate();
    const [sent, setSent] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const { pathname } = useLocation();

    const [templates, setTemplates] = useState([{Name: "address_locales", Tags: { "DisplayName": {
        "Name": "DisplayName",
        "Value": "Addresses",
        "Private": false,
        "Required": true,
        "Editable": false
}}}]);


    useLayoutEffect(() => {
        
        sessionStorage.setItem('redirectto', pathname)

        const individualid = getInfo("IndividualID");
        FetchIndividual(individualid)
            .then((individualData) => {
                setIndividual(individualData);
                setAddrList(individualData.addresses);
                //setSent(Object.keys(individualData.addresses).length === 1 ? false : true);
                if(Object.keys(individualData.addresses).length > 0) setAddressSelected(individualData.addresses[Object.keys(individualData.addresses)]);
            })
            .catch((error) => {
                console.error("Error fetching individual:", error);
            });
        GetSecuredContents().then((resp) => {
            setSecuredContents(resp)
        }).catch(err => console.log('Error getting secured content', err))
    }, []);

    useEffect(() => {
        if(contenttype !== "" && securedContents.filter(a => a.tags?.contenttype?.Value === contenttype).length > 0) 
            setSent(false);
        if(contenttype){
            const selected = templates.find(ele =>  contenttype === ele.Name);
            if(selected) 
                setSelectedTemplate(selected);
        }
    }, [contenttype, securedContents, templates]);

    const getTemplates = (spid) => {
        
        GetTemplatesByServiceProviderID(spid).then((templateIdsArr) => {
            templateIdsArr.forEach((id) => {
                GetTemplateByID(id).then((t) => {  
                setTemplates(templates => [...templates, t]);
            }).catch(err => console.log('Err in getting template details', err))
            })
        }).catch(err => console.log('Err in getting template', err));
    }
    
    
      useEffect(() => {
        let defaultSP = getInfo('DefaultServiceProvider');
        if(defaultSP)
            getTemplates(defaultSP);
        if(!isBen && defaultSP !== id)
            getTemplates(id);
      },[id, isBen])
    

    const connect = () => {
        let contentid = "", addressid = "";
        if(contenttype === "address_locales") {
            addressid = addressSelected.id;
        } else {
            //document.getElementById('contentselect').value;
            contentid = document.getElementById('contentselect').value;
        }
        if(isBen){
            var def = getInfo("DefaultServiceProvider")
            CreatePrimaryToken(def, addressid , individual.id, contentid).then((resp) => {
                CreateSecondaryToken(def, id ,resp.token).then(()=> {
                    setShowAlert(true);
                    setAlertText("Contact shared with Beneficiary");
                    setSent(true);
                 
                }).catch(e=>{
                    console.error("Error contacting Beneficiary", e);
                    setAlertText("Error contacting Beneficiary, please try again!");
                    setShowAlert(true)
                })
                }).catch((err) => {
                console.error("Error contacting Beneficiary", err);
                setAlertText("Error contacting Beneficiary, please try again!");
                setShowAlert(true)
            })
        } else {
        CreatePrimaryToken(id,addressid , individual.id, contentid).then(() => {
            setShowAlert(true);
            setAlertText("Contact shared with Service Provider");
            setSent(true);
        }).catch((err) => {
            console.error("Error contacting Service Provider", err);
            setAlertText("Error contacting Service Provider, please try again!");
            setShowAlert(true)
        })
        }
    }

    const cancelFn = () => {
        navigate(`/search/?text=${text}`)
    }

    const handleInputChange = (e) => {
        const { value } = e.target;
        if(contenttype === "address_locales") {
        setAddressSelected(addrList[value]);
        } else {
            setSelectedContentId(value);
        }
        setSent(false);
    };

    const updateContentType = (e) => {
        const val = e.target.value;
        setContenttype(val);
        if(val === "address_locales") {
            setSent(Object.keys(addrList).length === 0)
        } else {
            setSent(!(securedContents.length > 0 &&  securedContents.filter(a => a.tags?.contenttype?.Value === val).length > 0))
        }
        const selected = templates.find(ele =>  val === ele.Name);
        setSelectedTemplate(selected);
    }

    return <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <div className="mx-auto max-w-screen-lg form-container p-5">
            <div>
                <h1 className="form-label py-2">
                    <AiOutlineImport className="inline-icon" />
                    Connect with Service Provider
                </h1>
            </div>

            <div className="grid grid-cols-1">

                <form className="" id="address select">
                    <label className="form-label" htmlFor="addressselect">
                        What would you like to share? 
                    </label>
                    <select id="contenttypeselect" onChange={updateContentType} className="col-span-12 form-input px-2 py-2">
                    <option key="" value="">Choose Content type</option>
                    { templates.map((element, index) => {
                    return <option key={element.Name} value={element.Name} selected={contenttype === element.Name} >{element?.Tags?.DisplayName?.Value || element.Name}</option> 
                    }) 
                    }</select>
            { contenttype === "address_locales" ? <><label className="form-label" htmlFor="addressselect">
                        Choose {templates.map((ele) => { if(contenttype === ele.Name) return ele?.Tags?.DisplayName?.Value || ele.Name} )}
                    </label>
                    {
                        Object.keys(addrList).length === 0 ?
                            <><p className="py-3">You don't have any address(es) yet.
                                <Link className="" to={{ pathname: "/AddAddressDetails" }} state={{ addresses: individual.addresses, previousPage: "/contactServiceProvider/" + id,searchedText:text }} >
                                    <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="Add New Address">
                                        <AiOutlineProfile className="inline-icon" /> Add New Address
                                    </button>
                                </Link>  </p></>
                            : <select defaultValue={`${addressSelected?.id || Object.keys(addrList)[0]}`} onChange={handleInputChange} className="form-input" name="addressselect" id="addressselect">
                                {Object.keys(addrList || {}).map((addressId, index) => {
                                    const address = addrList[addressId];
                                    return (<option key={`${address.id}`} value={`${address.id}`}>{`${address.tags?.atag?.Name}`}</option>);
                                })}
                            </select>
                    } </> 
                    : contenttype === "" ? <></> 
                    : securedContents.length > 0 &&  securedContents.filter(a => a.tags?.contenttype?.Value === contenttype).length > 0 ? 
                        <select id="contentselect" defaultValue={`${selectedContentId}`} onChange={handleInputChange} className="form-input" >
                         { securedContents.filter(a => a.tags?.contenttype?.Value === contenttype).sort((a, b) => { return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase()) }).map((sc, index) => {
                            return <option key={sc.id} value={sc.id} selected={index === 0}>{selectedTemplate ? sc.tags[ProcessTemplate(selectedTemplate).contentNameTag]?.Value : sc.tags?.name?.Value}</option> }) 
                         } 
                      </select> 
                    : <><p className="py-2">You don't have any {templates.map((ele) => { if(contenttype === ele.Name) return ele?.Tags?.DisplayName?.Value || ele.Name} )} yet.</p>
                                <Link className="" to={{ pathname: "/addsecurecontent/" }} state={{ contenttype: contenttype, template: selectedTemplate , previousPage: "/contactServiceProvider/" + id,searchedText:text }} >
                                    <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="Add New Address">
                                        <AiOutlineProfile className="inline-icon" /> Add New {templates.map((ele) => { if(contenttype === ele.Name) return ele?.Tags?.DisplayName?.Value || ele.Name} )}
                                    </button>
                                </Link></>
                }
                </form>

                {/* {renderField("Message", "msg", "textarea",  messageVal , setMessageVal)} */}
                <div class="flex justify-center items-center space-x-4 py-2 ">
                    <button onClick={connect} style={{display:  sent   ? "none" : "block"}} type="submit" className="button-style positive-action">
                        Connect
                    </button>
                    {!sent ? (
                        <button onClick={cancelFn} className="button-style">Cancel</button>
                    ) : (
                        <button onClick={cancelFn} className="button-style">Close</button>
                    )}
                    <div
                        class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative w-full"
                        role="alert"
                        style={{ display: showAlert ? "block" : "none" }}
                    >
                                        <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button><span class="text-sm">{alertText} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}