import { useCallback, useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { CreateStream, GetStream, UpdateStream, UpdateStreamContent, GetStreamContent, ConvertUnixTimestampToDateString, GetRequestDetails, GetSecondaryTokenForRequest, GetSharedStream, GetSharedStreamContent, GetSharesWith, getInfo } from "../utilities/calls";
import { AiOutlineFile } from "react-icons/ai";
import { renderField, handleAccordionClick, createFormDataForSelectedFile } from "../utilities/functions";
import Tags from "./Tags";
import CopyToClipboard from "./CopyToClipboard";
import { FaFileDownload } from "react-icons/fa";
import * as dayjs from "dayjs";


export default function StreamDetails({ sharedStream, isReadOnly, sharedBeneficiaryId, sharedToken, sharedIndId }) {
    const { id } = useParams();
    const [stream, setStream] = useState({ Name: "", Description: "" });
    const [sent, setSent] = useState(true);
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [customTags, setCustomTags] = useState([])
    const [formData, setFormData] = useState();
    let { state } = useLocation();
    const readonly = state?.readonly || isReadOnly || false;
    const requestid = state?.requestID;
    const [request, setRequest] = useState();
    const [token, setToken] = useState();
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [recBenId, setRecBenId] = useState();
    const [recIndId, setRecIndId] = useState();

    useEffect(() => {
        if (sharedStream) {
            let strm = sharedStream;
            let Tags = strm?.tags;
            if (Tags?.Name) {
                strm.Name = Tags.Name.Value;
                delete Tags.Name;
            }
            setCustomTags(Tags);
            setStream(strm);
        }
    }, [sharedStream]);

    const getStreamDetails = useCallback((id) => {
        if (state?.stream) {
            let strm = state.stream;
            let Tags = strm.tags;
            if (Tags.Name) {
                strm.Name = Tags.Name.Value;
                delete Tags.Name;
            }
            setCustomTags(Tags);
            setStream(strm);
        } else {
            GetStream(id).then((resp) => {
                let Tags = resp.tags;
                if (Tags.Name) {
                    resp.Name = Tags.Name.Value;
                    delete Tags.Name;
                }
                setCustomTags(Tags);
                setStream(resp);

            }).catch((err) => { console.log('Error getting stream', err) })
        }
    },[state?.stream]);


    useEffect(() => {
        if (id !== undefined) {
            getStreamDetails(id);
        }
        if (requestid) {
            GetRequestDetails(requestid)
                .then((request) => {
                    setRequest(request);
                    if (request.fromBeneficiaryID !== "" || request.fromServiceProviderID === "") {
                        GetSecondaryTokenForRequest(request.id)
                            .then((response) => {
                                GetSharesWith(getInfo("IndividualID")).then(records => {
                                    const record = records.find(rec => rec.token === response.token);
                                    setToken(response.token)
                                    setRecBenId(record.beneficiaryID);
                                    setRecIndId(record.individualID);
                                    GetSharedStream(
                                        record.streamID,
                                        record.token,
                                        record.beneficiaryID,
                                        record.individualID
                                    ).then((stream) => {
                                        let Tags = stream.tags;
                                        if (Tags.Name) {
                                            stream.Name = Tags.Name.Value;
                                            delete Tags.Name;
                                        }
                                        setCustomTags(Tags);
                                        setStream(stream);
                                    }).catch((error) => {
                                        console.error("Error fetching stream details:", error);
                                        setAlertText("Error fetching stream details, please try again!");
                                        setShowAlert(true)
                                    }
                                    );
                                }).catch(err => console.log('Error in get records for streamid', err))
                            })
                            .catch((error) => {
                                console.error(
                                    `Error fetching details for token with request ID ${requestid}:`,
                                    error
                                )
                                setAlertText("Error fetching address details, please try again!");
                                setShowAlert(true)
                            }
                            );
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching request details for ID ${requestid}:`,
                        error
                    )
                    setAlertText("Error fetching address details, please try again!");
                    setShowAlert(true)
                }
                );
        }

    }, [id, requestid, getStreamDetails]);

   
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStream((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setSent(false);
        setShowAlert(false);
    };

    const addCustomTag = (tag, key) => {
        var tagname = key ? key : tag.Name;
        setCustomTags((prevValues) => ({
            ...prevValues, [tagname]: tag
        }))
        setSent(false);
        setShowAlert(false);
    }

    const removeCustomTag = (tagName) => {
        var newMap = { ...customTags };
        delete newMap[tagName]
        setCustomTags({ ...newMap });
        setSent(false);
        setShowAlert(false);
    }

    const handleFileUploadChange = (e) => {
        let formdata = createFormDataForSelectedFile(e);
        setFormData(formdata)
        setSent(false);
        setShowAlert(false);
    }
    const handleDownload = () => {
        setShowConfirmationBox(false)
        if (requestid) {
            GetSharedStreamContent(request.streamID,
                token,
                recBenId,
                recIndId).then(resp => {
                    const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
                    const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = filename;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }).catch(err => {
                    console.log('Error in downloading content', err);
                    setAlertText("Error in downloading the document.");
                    setShowAlert(true);
                })
        } else if (state?.token) {
            GetSharedStreamContent(id, state?.token, state?.beneficiaryID, state?.individualID).then(resp => {
                const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
                const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }).catch(err => {
                console.log('Error in downloading content', err);
                setAlertText("Error in downloading the document.");
                setShowAlert(true);
            })
        } else if (sharedStream) {
            GetSharedStreamContent(sharedStream.ID, sharedToken, sharedBeneficiaryId, sharedIndId).then(resp => {
                const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
                const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }).catch(err => {
                console.log('Error in downloading content', err);
                setAlertText("Error in downloading the document.");
                setShowAlert(true);
            })
        } else {
            GetStreamContent(id).then(resp => {
                const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
                const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }).catch(err => {
                console.log('Error in downloading content', err);
                setAlertText("Error in downloading the document.");
                setShowAlert(true);
            })
        }
    }
    const processForm = () => {
        var mergedData = { Description: stream.Description }
        mergedData.tags = { ...mergedData.tags, ...customTags }
        mergedData.tags.Name = {
            Name: "Name",
            Value: stream.Name,
            Private: false,
            Required: false,
            Editable: false
        }
        if (formData) {
            mergedData.tags.__SYS_JW_Type = {
                Name: "Type",
                Value: formData.get('type'),
                Private: false,
                Required: false,
                Editable: false
            }
            mergedData.tags.__SYS_JW_FileName = {
                Name: "FileName",
                Value: formData.get('name'),
                Private: false,
                Required: false,
                Editable: false
            }
            formData.delete('name');
            formData.delete('type');
        }
        if (id) {
            UpdateStream(id, mergedData).then(resp => {
                if (formData) {
                    UpdateStreamContent(id, formData.get('file')).then(resp => {
                        setSent(true);
                        setShowAlert(true);
                        setAlertText("saved successfully!");
                        getStreamDetails(id);
                    }).catch(err => console.log('Error in uploading file', err));
                } else {
                    setSent(true);
                    setShowAlert(true);
                    setAlertText("saved successfully!");
                    getStreamDetails(id);
                }
            }).catch(err => console.log('Error in creating a document', err))
        } else {
            CreateStream(mergedData).then(stream => {
                if (formData) {
                    UpdateStreamContent(stream.ID, formData.get('file')).then(resp => {
                        setSent(true);
                        setShowAlert(true);
                        setAlertText("saved successfully!");
                        getStreamDetails(stream.ID);
                    }).catch(err => console.log('Error in uploading file', err));
                } else {
                    setSent(true);
                    setShowAlert(true);
                    setAlertText("saved successfully!");
                    getStreamDetails(stream.ID);
                }
            }).catch(err => console.log('Error in creating a stream', err))
        }
    }

    const handleDownloadClick = (id) => {
        if (requestid || state?.token) {
            setShowConfirmationBox(true);
        } else {
            handleDownload();
        }
    }

    return <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <div className="mx-auto max-w-screen-lg form-container p-5">
            <div>
                <h1 className="form-label">
                    <AiOutlineFile className="inline-icon" />
                    <span>{requestid || readonly ? "Document" : id ? "Update Document" :  "Add Document"}</span>
                </h1>
                {id ? <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between"><span className="text-xs md:font-normal text-gray-200" id="docid">{id}<CopyToClipboard elId="docid" /></span><span className="md:items-end md:text-right text-xs italic">{"Last update on " + dayjs(ConvertUnixTimestampToDateString(stream.updated)).format("MMM D, YYYY")}</span></div> : <></>}
            </div>
            <div id="accordion-collapse" data-accordion="collapse" className="p-5">
                <h2 id="accordion-collapse-heading-1">
                    <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
                        <span>Document Details </span>
                        <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                        </svg>
                    </button>
                </h2>
                <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                    <div class="p-5 border border-grey-700">
                        <fieldset disabled={readonly ? "disabled" : ""}>
                            {renderField("Name", "Name", "alphanumeric", stream.Name, handleInputChange)}
                            {renderField("Description", "Description", "alphanumeric", stream.Description, handleInputChange)}
                        </fieldset>
                        {stream?.tags?.__SYS_JW_FileName?.Value ? <div className="grid grid-cols-12">
                            <label className="col-span-12 form-label" htmlFor={id}>
                                Document:<button formMethod="dialog" formTarget="top" id="download" onClick={() => handleDownloadClick(stream.ID)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1 ms-1">
                                    <FaFileDownload className="inline-icon" style={{ height: "1rem", width: "1rem" }} /> {stream?.tags?.__SYS_JW_FileName?.Value || "Download"}
                                </button>
                            </label>  </div> : <></>}
                        <fieldset disabled={readonly ? "disabled" : ""}>

                            {renderField(id ? "Replace Content from" : "Upload Content from", "File", "fileupload", "", handleFileUploadChange)}

                        </fieldset>
                    </div>
                </div>
                <h2 id="accordion-collapse-heading-3">
                    <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border gap-3" data-accordion-target="accordion-collapse-body-3" onClick={handleAccordionClick}>
                        <span>More Details </span>
                        <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                        </svg>
                    </button>
                </h2>
                <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                    <div class="p-5 border border-grey-700">
                        <fieldset >
                            <div className="py-5"><Tags isReadOnly={readonly} saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag} /></div>
                        </fieldset>
                    </div>

                </div>


            </div>
            <section className="flex flex-start gap-2 py-2">
                <button style={{ width: "fit-content", display: sent ? "none" : "block", }} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="button-style positive-action">
                    {id ? "UPDATE" : "ADD"}
                </button>

                {!sent ? (
                    <Link className="  button-style" to={state?.previousPage ? state.previousPage : "/documents"} state={{ request: state?.arequest, "contenttype": state?.contenttype }}>Cancel</Link>
                ) : (
                    <Link className="  button-style" to={sharedStream ? "/" : state?.previousPage ? state.previousPage : "/documents"} state={{ request: state?.arequest, "contenttype": state?.contenttype }}>Close</Link>
                )}
                <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative w-full" role="alert" style={{ display: showAlert ? "block" : "none" }}>
                <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button><span class="text-sm">{alertText} </span>
                </div>
            </section>
        </div>
        {showConfirmationBox && <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">

            <div className='w-11/12 max-w-[500px] rounded-lg border border-gray-700 bg-gray-800 flex flex-col gap-2 relative'>

                <button className="absolute top-4 right-1 text-lg font-normal text-gray-400  -translate-x-2 -translate-y-1" onClick={() => setShowConfirmationBox(false)}>X</button>


                <div className='flex flex-col gap-5 items-center p-6 mx-4 text-base font-normal text-white'>
                    <p className='font-medium'>Download Document</p>

                    <p class="mb-4 text-gray-300">By continuing, you agree to not store, not use it for creating a database or not use it for long term.</p>
                    <div class="flex justify-center items-center space-x-4">
                        <button onClick={handleDownload} type="submit" class="py-2 px-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none bg-blue-500 hover:bg-blue-600 focus:ring-blue-900">
                            Continue
                        </button>
                        <button onClick={() => setShowConfirmationBox(false)} type="button" class="py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:text-white hover:bg-gray-600 focus:ring-gray-600">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>}
    </div>

}