import { BsFillTrash3Fill } from "react-icons/bs";
import CopyToClipboard from './CopyToClipboard';
import DeleteModal from './DeleteModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GetGlobalTemplatesList, getInfo, GetSecuredContents, DeleteSecureContent, GetSharesForSecureContent, GetTemplatesByServiceProviderID, GetTemplateByID, GetServiceProvider, GetBeneficiary } from "../utilities/calls";
import {  AiOutlineProfile } from "react-icons/ai";
import AddressList from "./AddressList";
import { Link } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import { handleAccordionClick, displayDate, renderSharesWith, getSharesWith} from "../utilities/functions";
import { ProcessTemplate, FirstMatchedTemplate } from "../utilities/templates";
import ShareModal from "./ShareModal";

export default function SecureContentList() {
    const [securedcontents, setSecuredcontents] = useState([]);
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [currSpId, setCurrSpId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [sharedCounts, setSharedCounts] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState();
    const [selectedContentTitle, setSelectedContentTitle] = useState();
    const [unmatchedSecuredContent, setUnmatchedSecuredContent] = useState([]);
    const [addrCount, setAddrCount] = useState();
    const [sharesWith, setSharesWith] = useState({});
    const [spDetails, setSpDetails] = useState({});
    const [benDetails, setBenDetails] = useState({});
     
    let spIds = [];
    let timer = undefined;
    const defaultSP = getInfo('DefaultServiceProvider');
    const [defaultTemplates, setDefaultTemplates] = useState([{Name: "address_locales", Tags: { "DisplayName": {
        "Name": "DisplayName",
        "Value": "Addresses",
        "Private": false,
        "Required": true,
        "Editable": false
    }}}]);
    const [templates, setTemplates] = useState([]);
    useEffect(() => {
        loadList();
        if(defaultSP) {
        GetTemplatesByServiceProviderID(defaultSP).then((templateIdsArr) => {
            templateIdsArr.forEach((id) => {
                GetTemplateByID(id).then((template) => setDefaultTemplates((templates) => [...templates, template])).catch(err => console.log('Error fetching templates for spid ', id, err));
            })
        }).catch(err => console.log('Error fetching templates for spid ', err));
    }
    return () => clearTimeout(timer);
    }, [timer]);

    useEffect(() => {
        //setUnmatchedSecuredContent([]);
        let unmsc = [];
        securedcontents.forEach((sc) => {
            if (!FirstMatchedTemplate(sc,templates) && !FirstMatchedTemplate(sc,defaultTemplates)) {
               unmsc.push(sc);
            }
        })
        const matchGlobalList = (spid, newtemplates) => {
            if (spid === defaultSP)
                return;
            newtemplates.forEach((id) => {
                GetTemplateByID(id).then((gt) => {
                    unmsc.forEach((sc, index) => {
                        let newmatch = FirstMatchedTemplate(sc,[gt]);
                        if (newmatch) {
                            setTemplates((prev) => [...prev, newmatch])
                            unmsc.splice(index,1)
                            setUnmatchedSecuredContent(unmsc)
                        }
                    })
                }).catch(err => console.log('Error fetching templates for newtemplate ids ', id, err));
            })
            
        }
        
        GetGlobalTemplatesList("", matchGlobalList);
    }, [defaultTemplates, securedcontents]);

    

    
    const getTemplates = (spid) => {
        if (spid === defaultSP)
            return;
        GetTemplatesByServiceProviderID(spid).then((templateIdsArr) => {
            templateIdsArr.forEach((id) => {
                GetTemplateByID(id).then((template) =>  setTemplates((templates) => [...templates, template]) ).catch(err => console.log('Error fetching templates for spid ', spid, err));
            })
        }).catch(err => console.log('Error fetching templates for spid ', spid, err));
    }

    const loadList = () => {
        const individualid = getInfo("IndividualID");
        if (individualid === null) return;
        GetSecuredContents()
            .then((resp) => {
                let referredSpIds = [];
                let referredBenIds = [];
                setSecuredcontents(resp);
                const fetchCounts = async (contents) => {
                    const sharesPromises = contents.map((c) =>
                      GetSharesForSecureContent(c.id)
                            .then((response) => {
                                response.forEach(share => {
                                    if (spIds.findIndex((v) => v === share.serviceProviderID) === -1) {
                                        spIds.push(share.serviceProviderID); getTemplates(share.serviceProviderID)
                                    }
                                });
                                let sharesWithArr = getSharesWith(response, referredSpIds, referredBenIds)
                                referredBenIds.forEach(benId => GetBeneficiary(benId).then((el) => setBenDetails((benList) => ({...benList, [benId] : el}))).catch((err) => console.log(err)))
                                referredSpIds.forEach(spId => GetServiceProvider(spId).then((el) => setSpDetails((spList) => ({...spList, [spId] : el}))).catch((err) => console.log(err)))
    
                                return { securedcontentid: c.id, count: response.length, sharesWith : sharesWithArr };
                            })
                        .catch((error) => {
                          console.error(`Error shares for ID ${c.id}:`, error);
                          setShowAlert(true);
                          setAlertText("Error fetching shares");
                        })
                    );
                    Promise.all(sharesPromises)
                      .then((details) => {
                        const cmap = {};
                        const sharesMap = {};
                        details.forEach(
                          (detail) => {cmap[detail.securedcontentid] = detail.count;
                            sharesMap[detail.securedcontentid] = detail.sharesWith;
                          })
                        setSharedCounts(cmap);
                        setSharesWith(sharesMap);
                      })
                      .catch((error) => {
                        console.error("Error setting record details:", error);
                        setShowAlert(true);
                        setAlertText("Error setting record details");
                      });
                  };
                  if (resp && resp.length > 0) {
                    fetchCounts(resp);
                  }
            })
            .catch((error) => {
                console.error("Error in getSecureContent:", error);
                setShowAlert(true);
                setAlertText('Error loading data, please try again')
            });
    }

    
    const handleDeleteClick = (spid) => {
        setShowDeleteDlg(true);
        setCurrSpId(spid);
    }

    const deleteSC = () => {
        setShowDeleteDlg(false);

        DeleteSecureContent(currSpId, true).then(() => {
            setShowAlert(true);
            setAlertText("Secured Content was successfully deleted.");
            setTemplates([])
            loadList();
            timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        });
    }

    const handleShareClick= (sc, title) => {
        setSelectedContent(sc);
        setSelectedContentTitle(title);
        setShowShareModal(true);
    }

    const showSharedWithModal = (scid) => {
        var el = document.getElementById("sc_" + scid);
        el.classList.remove("invisible");
      }
      
      const hideSharedWithModal = (scid) => {
        document.getElementById("sc_" +scid).classList.add('invisible')
      }

    const renderRow = (sc, contenttype, scount, index, template) => {
        return (<li class="py-3 sm:py-2">

            <div class="flex items-center space-x-4 rtl:space-x-reverse">

                <div class="flex-1">
                    <Link class="font-semibold truncate" to={`/addsecurecontent/${sc.id}`} state={{"contenttype": contenttype, "template": template}}>

                        {template ? sc?.tags[ProcessTemplate(template).contentNameTag]?.Value || "No Name" : sc?.tags["title"]?.Value || sc?.tags["name"]?.Value}

                    </Link>
                    {renderSharesWith("sc_", sc.id, scount,  sharesWith[sc?.id],showSharedWithModal, hideSharedWithModal, spDetails, benDetails)}

                </div>

                <div class="inline-flex items-center text-white">
                    <button formMethod="dialog" formTarget="top" id="deleteSP" onClick={() => handleDeleteClick(sc.id)} className="bg-slate-333 py-1 hover:bg-555">
                        <BsFillTrash3Fill title="Delete" className="inline-icon" />
                    </button>
                </div>
            </div>
            <p className="mt-1 text-xs text-gray-400">{sc.updated ? "Updated " + displayDate(sc.updated) : ""}  </p>

            {sc.updated && sc.created && sc.updated !== sc.created ? <p className="text-xs text-gray-400">{sc.created ? "Created " + displayDate(sc.created) : ""}</p> : <></>}

            <p class="text-sm text-gray-200 mt-1">
                <span id={"sc_guid_" + index}>{sc.id}</span><CopyToClipboard elId={"sc_guid_" + index} />
            </p>
            <button formMethod="dialog" formTarget="top" id="sharecontent" onClick={() => handleShareClick(sc, template ? sc?.tags[ProcessTemplate(template).contentNameTag]?.Value || "" : sc?.tags["title"]?.Value || sc?.tags["name"]?.Value)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                <FaShareAlt className="inline-icon"  style={{height: "1rem" , width: "1rem" }} />Share
            </button>
        </li>
        )
    }

    const renderSecuredContentList = (contenttype, name, template) => {
        if (securedcontents?.length === 0)
            return <p>No {name} found.</p>
        return  (securedcontents.filter(a => a?.tags[ProcessTemplate(template).contentTypeTag]?.Value  === contenttype).sort((a, b) => { return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase()) }).map((sc, index) => {
            const scount = sharedCounts[sc.id]

            return renderRow(sc, contenttype, scount, index, template)

        })) 
    }

    const renderGeneralSecuredContentList = () => {
        if (unmatchedSecuredContent?.length === 0)
            return <p>No General Content</p>
        return  (unmatchedSecuredContent.sort((a, b) => { return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase()) }).map((sc, index) => {
            const scount = sharedCounts[sc.id]

            return renderRow(sc, "notes", scount, index)

        })) 
    }

    return (

        <div className='space-y-3 px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center'>
            {showAlert ? (
                <div
                    class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                    role="alert"
                    style={{ display: showAlert ? "block" : "none" }}
                ><button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button>
                    <span class="text-sm">{alertText} </span>
                </div>
            ) : (
                <></>
            )}
            <DeleteModal confirmationText="Are you certain you wish to delete this secured content?" deleteLabel="Delete" onDeleteFn={deleteSC} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />

            <h1 className="bold-text-input">Secure Content List ( Count {securedcontents.length + addrCount} )</h1>
            <p>{templates.length + defaultTemplates.length} content types found.</p>
             <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
                {defaultTemplates.sort((a, b) => { return (a?.Tags?.DisplayName?.Value || a.Name).toLowerCase().localeCompare((b?.Tags?.DisplayName?.Value || b.Name).toLowerCase()) }).map(element => {
                    return <>
                        <h2 id={"heading_" + (element?.Tags?.DisplayName?.Value || element?.Name)}>
                            <button type="button" class="flex text-left justify-start w-full py-3 font-medium rtl:text-right border-b border-gray-700 text-blue-400 gap-3" data-accordion-target={"body_" + (element?.Tags?.DisplayName?.Value || element?.Name)} aria-expanded="true" aria-controls={"body_" + (element?.Tags?.DisplayName?.Value || element?.Name)} onClick={handleAccordionClick}>
                                <svg data-accordion-icon class="w-3 h-3  shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg>
                                <span>{element?.Tags?.DisplayName?.Value || element?.Name} ( Count { (element?.Tags?.DisplayName?.Value || element?.Name) === "Addresses" ? addrCount : Object.keys(securedcontents.filter(a => a?.tags[ProcessTemplate(element).contentTypeTag]?.Value === element?.Name) || {}).length} )</span>
                                <span class="text-xs text-center font-medium me-2 px-2.5 py-0.5 rounded bg-gray-700 text-yellow-300 border border-yellow-300">Default template</span>
                            </button>
                        </h2>
                        <div id={"body_" + (element?.Tags?.DisplayName?.Value || element?.Name)} class="" aria-labelledby={"heading_" + (element?.Tags?.DisplayName?.Value || element?.Name)}>
                            <div class="pt-2 border-b border-gray-700">
                                {(element?.Tags?.DisplayName?.Value || element?.Name) === "Addresses" ? <AddressList contenttype={(element?.Tags?.DisplayName?.Value || element?.Name)} addressCount={setAddrCount} show={true} /> :
                                    <div className="px-1 md:px-4 py-1 max-w-screen-xl mx-auto items-center">
                                        <div >
                                            <Link className=" hover:bg-555 mb-2" to='/addsecurecontent/' state={{ "contenttype": (element?.Name), "template": element }}>
                                                <button className="button-style"> <AiOutlineProfile className='inline-icon' />
                                                Add {element?.Tags?.DisplayName?.Value || element?.Name}</button>
                                            </Link><ul class="w-full divide-y divide-gray-700 py-1" > {renderSecuredContentList((element?.Name), element?.Tags?.DisplayName?.Value || element?.Name, element)} </ul></div></div>}
                            </div>
                        </div>
                    </>
                })}    
                {templates.filter((a, index, arr) => arr.findIndex(b => (a.id === b.id)) === index).sort((a, b) => { return (a?.Tags?.DisplayName?.Value || a?.Name)?.toLowerCase().localeCompare((b?.Tags?.DisplayName?.Value || b?.Name)?.toLowerCase()) }).map(element => {
                    return <>
                        <h2 id={"heading_" + (element?.Tags?.DisplayName?.Value || element?.Name)}>
                            <button type="button" class="flex text-left justify-start w-full py-3 font-medium rtl:text-right  text-blue-400 gap-3  border-b border-gray-700" data-accordion-target={"body_" + (element?.Tags?.DisplayName?.Value || element?.Name)} aria-expanded="true" aria-controls={"body_" + (element?.Tags?.DisplayName?.Value || element?.Name)} onClick={handleAccordionClick}>
                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg>
                                <span>{element?.Tags?.DisplayName?.Value || element?.Name} ( Count {Object.keys(securedcontents.filter(a => a?.tags[ProcessTemplate(element).contentTypeTag]?.Value === (element?.Name))  || {}).length} ) </span>
                            </button>
                        </h2>
                        <div id={"body_" + (element?.Tags?.DisplayName?.Value || element?.Name)} class="hidden" aria-labelledby={"heading_" + (element?.Tags?.DisplayName?.Value || element?.Name)}>
                            <div class="pt-2 border-b border-gray-700">
                                <div className="px-1 md:px-4 py-1 max-w-screen-xl mx-auto items-center">
                                    <div>
                                        <Link className="hover:bg-555 mb-2" to='/addsecurecontent/' state={{ "contenttype":(element?.Name), "template": element }}>
                                            <button className="button-style"><AiOutlineProfile className='inline-icon' />
                                            Add {element?.Tags?.DisplayName?.Value || element?.Name}</button>
                                        </Link><ul class="w-full divide-y divide-gray-700 py-1"> {renderSecuredContentList((element?.Name), element?.Tags?.DisplayName?.Value || element?.Name, element)} </ul></div></div>
                            </div>
                        </div>
                    </>
                })}
                <>
                <h2 id={"heading_othersgeneral"}>
                            <button type="button" class="flex text-left justify-start w-full py-3 font-medium rtl:text-right gap-3  border-b border-gray-700" data-accordion-target={"body_othersgeneral" } aria-expanded="true" aria-controls={"body_othersgeneral" } onClick={handleAccordionClick}>
                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg>
                                <span>General Content ( Count {unmatchedSecuredContent.length} ) </span>
                            </button>
                        </h2>
                        <div id={"body_othersgeneral"} class="hidden" aria-labelledby={"heading_othersgeneral" }>
                            <div class="pt-2 border-b border-gray-700">
                                <div className="px-1 md:px-4 py-1 max-w-screen-xl mx-auto items-center">
                                 <ul class="w-full divide-y divide-gray-700 py-1"> {renderGeneralSecuredContentList()} </ul>
                                 </div></div></div></>
            </div>
            {showShareModal &&    <ShareModal content={selectedContent} onClose={() => {setTemplates([]);
            loadList();setShowShareModal(false);}} title={selectedContentTitle}/>} 


        </div>
    )

}