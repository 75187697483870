import { Link } from "react-router-dom";
import { AiOutlineProfile, AiOutlineQrcode } from "react-icons/ai";
import { GetAccount, DeleteAccount, GetServiceProvider, GetAccounts, DeleteBeneficiary, DeleteServiceProvider, UpdateAccount, getInfo, GetBeneficiary, CreateAccount, GetTemplatesByServiceProviderID } from "../utilities/calls";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "./CopyToClipboard";
import CreateBeneficiarySP from "./CreateBeneficiarySP";
import { BsFillTrash3Fill, BsScissors, BsBoxArrowInUpLeft, BsClipboard2Check } from "react-icons/bs";
import DeleteModal from "./DeleteModal";
import { displayLogoURL } from "../utilities/functions";
import { CgTemplate } from "react-icons/cg";
import BusinessProfileAdminModal from "./BusinessProfileAdminModal";
import QRCodeModal from "./QRCodeModal";
import { FaLink } from "react-icons/fa";

export default function ServiceProviderList() {

    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [serviceProviderList, setServiceProviderList ] = useState([]);
    const [beneficiaryList, setBeneficiaryList ] = useState([]);
    const [unassignedserviceProviderList, setunassignedServiceProviderList ] = useState([]);
    const [unassignedbeneficiaryList, setunassignedBeneficiaryList ] = useState([]);
    const [currId, setCurrId] = useState();
    const [isSP, setIsSP] = useState();
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [showRemoveDlg, setShowRemoveDlg] = useState();
    const [showAssignDlg, setShowAssignDlg] = useState();
    const [showDeleteACDlg, setShowDeleteACDlg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const [confirmationRemoveText, setConfirmationRemoveText] = useState();
    const [confirmationAssignText, setConfirmationAssignText] = useState();
    const [confirmationDeleteText, setConfirmationDeleteText] = useState();
    const [spTemplates, setSpTemplates] = useState({});
    const [account, setAccount] = useState({});
    const [accountSPMap, setAccountSPMap] = useState({});
    const [accountBenMap, setAccountBenMap] = useState({});
    const [unusedAccounts, setUnusedAccounts] = useState({});
    const [showCreateBeneSP, setShowCreateBeneSP] = useState();
    const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
    const [benSet, setBEN] = useState();
    const [spSet, setSP] = useState();
    const [qrcode, setQrcode] = useState();
    const [isQrModalOpen, setIsQrModalOpen] = useState(false);
    let timer = undefined;

    const addServiceProvider = () => {
        setShowCreateBeneSP(true);
    }

    const showFn = (state) => {
      setShowCreateBeneSP(state);
    }
    const handleUnassignClick = (id) => {
      let isSP = serviceProviderList.findIndex((sp) => (sp.id === id)) !== -1;
      if(isSP) 
        setConfirmationText("Are you certain you wish to Unassign this service provider?");
      else 
        setConfirmationText("Are you certain you wish to Unassign this beneficiary?");
      setShowDeleteDlg(true);
      setCurrId(id);
      setIsSP(isSP);
    }
    const handleDeleteClick = (id) => {
      let isSP = unassignedserviceProviderList.findIndex((sp) => (sp.id === id)) !== -1;
      if(isSP) 
        setConfirmationRemoveText("Are you certain you wish to delete Unassigned service provider?");
      else 
        setConfirmationRemoveText("Are you certain you wish to delete Unassigned beneficiary?");
      setShowRemoveDlg(true);
      setCurrId(id);
      setIsSP(isSP);
    }
    
    const handleAssignClick = (id) => {
      let isSP = unassignedserviceProviderList.findIndex((sp) => (sp.id === id)) !== -1;
      if(isSP) 
        setConfirmationAssignText("Are you certain you wish to assign Unassigned service provider?");
      else 
        setConfirmationAssignText("Are you certain you wish to assign Unassigned beneficiary?");
      setShowAssignDlg(true);
      setCurrId(id);
      setIsSP(isSP);
    }

    const unassignSPBen = () => {
      setShowDeleteDlg(false);

      const individualid = getInfo("IndividudalID");
      let account = isSP ? accountSPMap[currId] : accountBenMap[currId]
      let serviceProviders = account.ServiceProviders || {};
      let beneficiary = account.Beneficiaries || {};
      if(isSP) {
        delete account.ServiceProviders[currId]
      } else {
        delete account.Beneficiaries[currId]
      }
      UpdateAccount(account.id, individualid, {}, serviceProviders, beneficiary).then((response) => {
        setShowAlert(true);
      setAlertText("Unassigned successfully" );
      setServiceProviderList([]);
      setBeneficiaryList([]);
      setunassignedServiceProviderList([]);
      setunassignedBeneficiaryList([]);
      updateList();
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      }).catch((error) => { console.log("Error in unassign", error);
      setShowAlert(true);
      setAlertText("Error unassigning.");
     })   
    }
    
    const assignSPBen = () => {
      setShowAssignDlg(false);
      let account = isSP ? accountSPMap[currId] : accountBenMap[currId]
        const newaccount = {};
      if(isSP) {
    	    let sp = account.ServiceProviders[currId]
        delete account.ServiceProviders[currId]
        newaccount.ServiceProviders = {};
        newaccount.ServiceProviders[currId] = sp
      } else {
    		let ben = account.Beneficiaries[currId]
        delete account.Beneficiaries[currId]
        newaccount.Beneficiaries = {};
        newaccount.Beneficiaries[currId] = ben
      }
      CreateAccount("", {}, newaccount.ServiceProviders, newaccount.Beneficiaries).then((response) => { 
            setAccount(response);
	      setAlertText("Assigned successfully" );
	      setServiceProviderList([]);
	      setBeneficiaryList([]);
	      setunassignedServiceProviderList([]);
	      setunassignedBeneficiaryList([]);
	      updateList();
	      timer = setTimeout(() => {
	        setShowAlert(false);
	      }, 2000);
      }).catch((error) => { console.log("Error in assign", error);
      setShowAlert(true);
      setAlertText("Error assigning.");
     })   
    }
    
    const deleteAC = () => {
      setShowDeleteACDlg(false);

      DeleteAccount(currId).then((response) => { 
            setAccount(response);
	      setAlertText("Deleted Account successfully" );
	      setServiceProviderList([]);
	      setBeneficiaryList([]);
	      setunassignedServiceProviderList([]);
	      setunassignedBeneficiaryList([]);
	      updateList();
	      timer = setTimeout(() => {
	        setShowAlert(false);
	      }, 2000);
      }).catch((error) => { console.log("Error in delete", error);
      setShowAlert(true);
      setAlertText("Error deleting.");
     })   
    }
    
    const deleteSPBen = () => {
      setShowRemoveDlg(false);
    	  if (isSP) {
	    	  DeleteServiceProvider(currId, false).then(() => {
			setShowAlert(true);
			setAlertText("Service Provider was successfully removed.");
			setunassignedServiceProviderList([]);
			setunassignedBeneficiaryList([]);
			setServiceProviderList([]);
      		setBeneficiaryList([]);
			updateList();
			timer = setTimeout(() => {
			    setShowAlert(false);
			}, 2000);
	      }).catch((error) => { console.log("Error in delete", error);
			      setShowAlert(true);
			      setAlertText("Error removing Service Provider.");
		  })
	  } else {
		DeleteBeneficiary(currId).then(() => {
			setShowAlert(true);
			setAlertText("Beneficiary was successfully removed.");
			setunassignedServiceProviderList([]);
			setunassignedBeneficiaryList([]);
			setServiceProviderList([]);
      		setBeneficiaryList([]);
			updateList();
			timer = setTimeout(() => {
			    setShowAlert(false);
			}, 2000);
	      }).catch((error) => { console.log("Error in delete", error);
			      setShowAlert(true);
			      setAlertText("Error removing Beneficiary.");
		  })
	  }
    }

    useEffect(() => {
      serviceProviderList.forEach((sp) => {
          GetTemplatesByServiceProviderID(sp.id).then((resp) => {
            setSpTemplates((spTemplates) => ({...spTemplates, [sp.id] : resp.length }))
          })
      });
      return () => clearTimeout(timer);
    }, [serviceProviderList,timer])

    const adminSP = ((sp) => {
      setSP(sp);
      setIsAdminModalOpen(true);
    });
    const adminBEN = ((ben) => {
      setBEN(ben);
      setIsAdminModalOpen(true);
    });

    const showQRCode = (sp) => {
      let linkForQR = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/home/index.html#/contactServiceProvider/' + sp.id;
      setQrcode(linkForQR);
      setIsQrModalOpen(true);
    };

    const copyShareLink = (sp, e) => {
      document.getElementById('c2cicon_' + sp.id).style.display = "";
      serviceProviderList.forEach((spl) => {
          if(spl.id !== sp.id){
              document.getElementById('c2cicon_' + spl.id).style.display = "none";
          }   
      })
      let shareLink = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/home/index.html#/contactServiceProvider/' + sp.id;
      navigator.clipboard.writeText(shareLink); 
    }

    const handleAdminClose = () => {
      setIsAdminModalOpen(false);
      setSP();
      setBEN();
    };
    
    const handleDeleteACClick = (id) => {
	  setConfirmationDeleteText("Are you sure you want to delete the Unused Accoout Profile?")
	  setCurrId(id)
      setShowDeleteACDlg(true);
    }

    const updateList = () => {
      GetAccounts().then((response) => {
        if(Object.keys(response).length === 0 ){
         CreateAccount("", {}, {}, {}).then((response) => { 
            setAccount(response);
          }).catch((err) => { console.log(err);})
        } else {
        	    setUnusedAccounts({});
	        Object.keys(response).forEach((key, index) => {
	            GetAccount(key).then((accDetails) => { setAccount(accDetails); 
	              Object.keys(accDetails.ServiceProviders).forEach((k, i)=> {
	                GetServiceProvider(k).then((spDetails) => {(
	                  setServiceProviderList((serviceProviderList)=>[...serviceProviderList, spDetails])); 
	                  setAccountSPMap((accountSPMap)=> ({...accountSPMap, [spDetails.id] : accDetails }));
	                })
	              })
	              Object.keys(accDetails.Beneficiaries).forEach((k, i)=> {
	                GetBeneficiary(k).then((spDetails) => {(
	                  setBeneficiaryList((beneficiaryList)=>[...beneficiaryList, spDetails]));
	                  setAccountBenMap((accountBenMap)=> ({...accountBenMap, [spDetails.id] : accDetails}));
	                })
	              })
	 			  if (Object.keys(accDetails.ServiceProviders).length === 0 && Object.keys(accDetails.Beneficiaries).length === 0) {
					setUnusedAccounts((unusedAccounts)=> ({...unusedAccounts, [accDetails.id] : accDetails }));
				  }
	             }).catch((error)=> {
	               console.log("Error in Get Account details", error);
	            });
	       	})
        }
        GetAccount("00000000-0000-0000-0000-000000000000").then((accDetails) => { setAccount(accDetails); 
          Object.keys(accDetails.ServiceProviders).forEach((k, i)=> {
            GetServiceProvider(k).then((spDetails) => {(
            	  setunassignedServiceProviderList((unassignedserviceProviderList)=>[...unassignedserviceProviderList, spDetails]));
            	  setAccountSPMap((accountSPMap)=> ({...accountSPMap, [spDetails.id] : accDetails })); 
            })
          })
          Object.keys(accDetails.Beneficiaries).forEach((k, i)=> {
            GetBeneficiary(k).then((benDetails) => {(
              setunassignedBeneficiaryList((unassignedbeneficiaryList)=>[...unassignedbeneficiaryList, benDetails]));
              setAccountBenMap((accountBenMap)=> ({...accountBenMap, [benDetails.id] : accDetails })); 
            })
          })

         }).catch((error)=> {
           console.log("Error in Get Account details", error);
        });
        
        
      }).catch((error) => {
        console.error("Error fetching accounts:", error);
        setShowAlert(true);
        setAlertText("Error fetching data from server. Please try again!");
      });
    
    }

    useEffect(() => {
          updateList();
          setShowCreateBeneSP(false);
      }, []);
    

    return <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
    <h1 className="bold-text-input">Service Provider Profiles</h1>
    
    <CreateBeneficiarySP show={showCreateBeneSP}  showFn={showFn}/>
    {isAdminModalOpen && benSet && (
                                <BusinessProfileAdminModal id="ben_admin"
                                  beneficiary={benSet}
                                  onClose={handleAdminClose}
                                />
                              )}
    {isAdminModalOpen && spSet && (
                                <BusinessProfileAdminModal id="sp_admin"
                                  serviceProvider={spSet}
                                  onClose={handleAdminClose}
                                />
                              )}
    <DeleteModal confirmationText={confirmationText} deleteLabel="Unassign" onDeleteFn={unassignSPBen} onCancelFn={()=>{setShowDeleteDlg(false)}} show={showDeleteDlg}/>
  	<DeleteModal confirmationText={confirmationRemoveText} deleteLabel="Remove" onDeleteFn={deleteSPBen} onCancelFn={()=>{setShowRemoveDlg(false)}} show={showRemoveDlg}/>
  	<DeleteModal confirmationText={confirmationAssignText} deleteLabel="Assign" onDeleteFn={assignSPBen} onCancelFn={()=>{setShowAssignDlg(false)}} show={showAssignDlg}/>
  	<DeleteModal confirmationText={confirmationDeleteText} deleteLabel="Delete" onDeleteFn={deleteAC} onCancelFn={()=>{setShowDeleteACDlg(false)}} show={showDeleteACDlg}/>
    {isQrModalOpen && (
				        <QRCodeModal
				          isOpen={isQrModalOpen}
				          onClose={() => {setIsQrModalOpen(false)}}
				          qrValue={qrcode}
				        />
				      )}
    <div style={{ marginTop: "20px" }}>
            {serviceProviderList.length === 0 && beneficiaryList.length === 0 ? <div className="w-full h-full flex items-start justify-start text-small">
                    <p className="text-start pb-2">Add your profile information so people can reach out to you. Click the button below to get started!</p></div>: <></>}
              <button className="button-style hover:bg-555" onClick={addServiceProvider}>
                <AiOutlineProfile className="inline-icon" />
                Add Service Provider Profile
              </button>
              <div className="p-3"></div>
              <div
              class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
              role="alert"
              style={{ display: showAlert ? "block" : "none" }}
            ><button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button>
              <span class="text-sm">{alertText} </span>
            </div>
            <ul class="w-full divide-y divide-gray-700 py-3">
            <h1 className="bold-text-input text-green-400">Unused Account Profiles {Object.keys(unusedAccounts).length}</h1>
                {Object.keys(unusedAccounts).map((key, index) => {
                	   let ac = unusedAccounts[key]
                    return <li class="pt-1 pb-3 sm:pb-4">
                       <div class="flex-shrink-0 md:flex-1 text-base text-white">
                            Account Profile                 
                            <button formMethod="dialog" formTarget="top" id="deleteAC" onClick={() => handleDeleteACClick(ac.id)} className="bg-slate-333 py-1 hover:bg-555 ml-4">
                            	<BsScissors title="Delete" className="inline-icon text-red-400" />
                          	</button>
                          </div>
                       <p class="text-sm text-gray-400">
                       <span id={"ac_guid_" +index }>{ac.id}</span><CopyToClipboard elId={"ac_guid_" +index }/>
                       </p>                   		
                    </li>
                      
                  })
                }
            </ul>
            <ul class="w-full divide-y divide-gray-700 py-3">
            <h1 className="bold-text-input">Active Profiles {Object.keys(serviceProviderList).length + Object.keys(beneficiaryList).length}</h1>
                {serviceProviderList.sort((a, b) => {return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase())}).map((sp, index) => {
                    return <li class="pt-1 pb-3 sm:pb-4">
                      
                       <div class="flex items-center space-x-4 rtl:space-x-reverse">
                       {displayLogoURL(sp.tags?.logourl?.Value)}

                          <div class="flex-1">
                             <p class="text-base font-semibold break-words text-blue-400 text-left">
                             <Link to={`/AddServiceProviderProfile/${sp.id}`} state={{previousPage:"/serviceProviders"}}>

                         {`${sp.tags?.name?.Value || "No Name"}`}</Link>
                             </p>
                             
                          </div>
                          
                          <div class="inline-flex items-center text-white">
                          <button formMethod="dialog" formTarget="top" id="deleteSP" onClick={() => handleUnassignClick(sp.id)} className="bg-slate-333 py-1 hover:bg-555">
                            <BsFillTrash3Fill title="Unassign" className="inline-icon" />
                          </button>
                          </div>
                       </div>
                       <div class="flex-shrink-0 md:flex-1 text-base text-white">
                            Service Provider-Account Profile {accountSPMap[sp.id].id}
                          </div>
                        <p class="text-base font-semibold truncate">
                             <button formMethod="dialog" formTarget="top" id={sp.id} onClick={() => adminSP(sp)} className="text-red-400 bg-slate-333 hover:bg-555 hover:underline py-1"> Manage Admins</button>
                        </p>
                       
                       <p class="text-sm text-gray-400">
                       <span id={"sp_guid_" +index }>{sp.id}</span><CopyToClipboard elId={"sp_guid_" +index }/>
                             </p>
                      <p><button formMethod="dialog" formTarget="top" id="templates" className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                      {spTemplates[sp.id] ? <Link to={`/serviceprovidertemplates/${sp.id}`}><CgTemplate className="inline-icon" />{spTemplates[sp.id] + " Templates"}</Link>
                        : <Link to="/addtemplate/" state={{"spid":sp.id, previousPage:"/serviceProviders" }} ><CgTemplate className="inline-icon" />Add Template</Link>}

                      </button>
                      </p><p>
                      <button onClick={(e) => copyShareLink(sp, e)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline "><FaLink className="inline-icon"/>Copy Share Link <BsClipboard2Check  fill="green" style={{display: "none"}} id={"c2cicon_" + sp.id  } className="inline-icon" title="Copied to clipboard"/></button>
                      <button formMethod="dialog" formTarget="top" id={sp.id} onClick={() => showQRCode(sp)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1 px-2"> <AiOutlineQrcode className="inline-icon"/>QRCode Sharing</button>
                      </p>
                    </li>
                      
                  })
                }
                {beneficiaryList.sort((a, b) => {return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase())}).map((ben, index) => {
                    return <li class="pt-1 pb-3 sm:pb-4">
                      
                       <div class="flex items-center space-x-4 rtl:space-x-reverse">
                       {displayLogoURL(ben.tags?.logourl?.Value)}

                          <div class="flex-1">

                             <p class="text-base font-semibold break-words text-blue-400 text-left">
                             <Link to={`/AddBeneficiaryProfile/${ben.id}`} state={{previousPage:"/serviceProviders"}}>
                              {`${ben.tags?.name?.Value || "No Name"}`}</Link></p>   
                          </div>
                         
                          <div class="inline-flex items-center text-white">
                          <button formMethod="dialog" formTarget="top" id="deleteBen" onClick={() => handleUnassignClick(ben.id)} className="bg-slate-333 py-1 hover:bg-555">
                            <BsFillTrash3Fill title="Unassign" className="inline-icon" />
                          </button>
                          </div>
                       </div>
                       <div class="flex-shrink-0 md:flex-1 text-base text-white">
                          Beneficiary-Account Profile {accountBenMap[ben.id].id}
                          </div>
                       <p class="text-base font-semibold truncate text-red-400">
                             <button formMethod="dialog" formTarget="top" id={ben.id} onClick={() => adminBEN(ben)} className="text-red-400 bg-slate-333 hover:bg-555 hover:underline py-1"> Manage Admins</button>
                        </p>
                       <p class="text-sm text-gray-400">
                       <span id={"ben_guid_" +index }>{ben.id}</span><CopyToClipboard elId={"ben_guid_" +index }/>
                        </p>
                    </li>
                      
                  })
                }
            </ul>
    </div>
    <div>
    <h1 className="bold-text-input">Unassinged Service Provider Profiles</h1>

            <ul class="w-full divide-y divide-gray-700 py-3">
            {unassignedserviceProviderList.sort((a, b) => {return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase())}).map((sp, index) => {
                    return <li class="pt-1 pb-3 sm:pb-4">
                      
                       <div class="flex items-center space-x-4 rtl:space-x-reverse">
                       	{displayLogoURL(sp.tags?.logourl?.Value)}

                        <div class="flex-1">
                           <p class="text-base font-semibold break-words text-blue-400 text-left">
                           <Link to={`/AddServiceProviderProfile/${sp.id}`} state={{previousPage:"/serviceProviders"}}>
                       	{`${sp.tags?.name?.Value || "No Name"}`}</Link>
                           </p>
                           
                        </div>
                        
	                    <div class="inline-flex items-center text-white">
	                       <button formMethod="dialog" formTarget="top" id="removeSP" onClick={() => handleDeleteClick(sp.id)} className="bg-slate-333 py-1 hover:bg-555">
	                         <BsScissors title="Remove" className="inline-icon" />
	                       </button>
						              <button formMethod="dialog" formTarget="top" id="assignSP" onClick={() => handleAssignClick(sp.id)} className="bg-slate-333 py-1 hover:bg-555 ml-4">
	                         <BsBoxArrowInUpLeft title="Assign back" className="inline-icon" />
	                       </button>
	                    </div>
	                   </div>
	                   <div class="flex-shrink-0 md:flex-1 text-base text-white">
	                         Service Provider
	                   </div>
	                   <p class="text-sm text-gray-400">
	                    <span id={"unassingedsp_guid_" +index }>{sp.id}</span><CopyToClipboard elId={"unassingedsp_guid_" +index }/>
	                   </p>
                    </li>
                      
                  })
                }
                {unassignedbeneficiaryList.sort((a, b) => {return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase())}).map((ben, index) => {
                    return <li class="pt-1 pb-3 sm:pb-4">
                      
                       <div class="flex items-center space-x-4 rtl:space-x-reverse">
                       	{displayLogoURL(ben.tags?.logourl?.Value)}

                          <div class="flex-1">

                             <p class="text-base font-semibold break-words text-blue-400 text-left">
                             <Link to={`/AddBeneficiaryProfile/${ben.id}`} state={{previousPage:"/serviceProviders"}}>
                              {`${ben.tags?.name?.Value || "No Name"}`}</Link></p>   
                          </div>
                       	<div class="inline-flex items-center text-white">
	                       <button formMethod="dialog" formTarget="top" id="removeBEN" onClick={() => handleDeleteClick(ben.id)} className="bg-slate-333 py-1 hover:bg-555">
	                         <BsScissors title="Remove" className="inline-icon" />
	                       </button>
						  <button formMethod="dialog" formTarget="top" id="assignBEN" onClick={() => handleAssignClick(ben.id)} className="bg-slate-333 py-1 hover:bg-555  ml-4">
	                         <BsBoxArrowInUpLeft title="Assign back" className="inline-icon" />
	                       </button>
	                    </div>
                       </div>
                       <div class="flex-shrink-0 md:flex-1 text-base text-white">
                          Beneficiary
                       </div>
                       <p class="text-sm text-gray-400">
                       	<span id={"unassingedben_guid_" +index }>{ben.id}</span><CopyToClipboard elId={"unassingedben_guid_" +index }/>
                        </p>
                    </li>
                      
                  })
                }
                </ul>
    </div>
    </div>
}