import React,{useState} from 'react';
import { AiOutlineProfile } from 'react-icons/ai';
import { address_locales, private_service_provider_profile, countryLocaleList } from "../utilities/templates";
import { renderField } from "../utilities/functions";
import { UpdateServiceProvider, UpdateIndividualPrivateSP,getInfo, FetchIndividual } from '../utilities/calls';

const ServiceProviderModal = ({setServiceProviderModal, serviceProviders, sp, onUpdate}) => {
    const currSP = sp();
    const [updatedaddress, updateAddress] = useState({
        name: currSP?.tags?.name?.Value || "",
        description: currSP?.tags?.description?.Value || "",
        categories: currSP?.tags?.categories?.Value || "",
        website: currSP?.tags?.website?.Value || "",
        street: currSP?.tags?.street?.Value ||"",
        city: currSP?.tags?.city?.Value || "",
        state: currSP?.tags?.state?.Value || "",
        country: currSP?.tags?.country?.Value || "",
        zipCode: currSP?.tags?.zipCode?.Value || "",
        email: currSP?.tags?.email?.Value || "",
        phone: currSP?.tags?.phone?.Value || "",
        addressee: currSP?.tags?.addressee?.Value || ""
      });
      const [selectedCountry, setSelectedCountry] = useState(
        Intl.NumberFormat().resolvedOptions().locale
      );
      const [sent, setSent] = useState(true);
      const [showAlert, setShowAlert] = useState();
      const [alertText, setAlertText] = useState();
    const handleInputChange = (e) => {
        if (e.target.name === "country") {
          handleCountryChange(e);
          return;
        }
        const { name, value } = e.target;
        updateAddress((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
    
        setSent(false);
      };
      const handleCountryChange = (e) => {
        const countryVal = countryLocaleList.find(
          (o) => o.name === e.target.value
        ).value;
        updateAddress((prevValues) => ({
          ...prevValues,
          [e.target.name]: e.target.value,
        }));
        setSent(false);
    
        setSelectedCountry(countryVal);
      };
      function handleAccordionClick(ev) {
        let dv, icon;
        if (ev.target.dataset && ev.target.dataset.accordionTarget) {
          dv = document.getElementById(ev.target.dataset.accordionTarget);
          icon = ev.target.querySelector("svg");
        } else {
          const button = ev.target.closest("button");
          dv = document.getElementById(button.dataset.accordionTarget);
          icon = button.querySelector("svg");
        }
        if (dv.classList.contains("hidden")) {
          dv.classList.add("block");
          dv.classList.remove("hidden");
          icon.classList.remove("rotate-180");
        } else {
          dv.classList.add("hidden");
          dv.classList.remove("block");
          icon.classList.add("rotate-180");
        }
      }

      const getMergedData = () => {
        var mergedData = {
          tags: {
            name: {
              Name: "name",
              Value: updatedaddress?.name,
              Private: false,
              Required: false,
              Editable: false,
            },
            description: {
              Name: "description",
              Value: updatedaddress?.description,
              Private: false,
              Required: false,
              Editable: false
            },
            categories: {
              Name: "categories",
              Value: updatedaddress?.categories,
              Private: false,
              Required: false,
              Editable: false
            },
            website: {
              Name: "website",
              Value: updatedaddress?.website,
              Private: false,
              Required: false,
              Editable: false
            },
            addressee: {
              Name: "addressee",
              Value: updatedaddress?.addressee,
              Private: false,
              Required: false,
              Editable: false
            },
            street: {
              Name: "street",
              Value: updatedaddress?.street,
              Private: false,
              Required: false,
              Editable: false,
            },
            city: {
              Name: "city",
              Value: updatedaddress?.city,
              Private: false,
              Required: false,
              Editable: false
            },
            state: {
              Name: "state",
              Value: updatedaddress?.state,
              Private: false,
              Required: false,
              Editable: false
            },
            zipCode: {
              Name: "zipCode",
              Value: updatedaddress?.zipCode,
              Private: false,
              Required: false,
              Editable: false
            },
            email: {
              Name: "email",
              Value: updatedaddress?.email,
              Private: false,
              Required: false,
              Editable: false
            },
            phone: {
              Name: "phone",
              Value: updatedaddress?.phone,
              Private: false,
              Required: false,
              Editable: false
            }
          }
        }
        return mergedData;
      }

      const saveSP = (mergedData) => {
        const individualid = getInfo("IndividualID");
        mergedData.individualid = individualid;
        mergedData.id = currSP.id;
        UpdateServiceProvider(currSP.id, mergedData, true)
        .then((response) => {
          setShowAlert(true);
          setAlertText("Service Provider added!");
          setSent(true);
          onUpdate();
        })
        .catch((error) => {
          console.error("Error UPDATING service provider details:", error);
          setShowAlert(true);
          setAlertText("Save Service Provider profile failed! Sorry!");
        });
      } 

      const addServiceProviderAndSaveSP = (mergedData) => {
        const individualid = getInfo("IndividualID");
        mergedData.individualid = individualid;
        serviceProviders.push("00000000-0000-0000-0000-000000000000");
    
        UpdateIndividualPrivateSP(individualid, serviceProviders).then((response) => {
          FetchIndividual(individualid).then((resp) => {
          let spid = resp.privateserviceproviders.find((el) => serviceProviders.indexOf(el.id) === -1).id;
          mergedData.id = spid

          UpdateServiceProvider(spid, mergedData, true).then((r) => {
            setShowAlert(true);
            setAlertText("Service Provider added!");
            setSent(true);
            onUpdate();
          })
            .catch((error) => {
              console.error("Error UPDATING service provider details:", error);
              setShowAlert(true);
              setAlertText("Save Service Provider profile failed! Sorry!");
            });
          })}).catch((error) => { console.log("Error in updateAccount", error) })
      
      }
    

      const processForm=()=>{
        var mergedData = getMergedData();
        if (currSP && currSP.id)
          saveSP(mergedData);
        else {
          addServiceProviderAndSaveSP(mergedData)
        }
        setServiceProviderModal(false)
      }
      const cancelFn=()=>{
        setServiceProviderModal(false)
      }
      
      return (
    <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">
        <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="form-label">
            <AiOutlineProfile className="inline-icon" />
            Service Provider Details
          </h1>
          <h4 className="text-sm py-2">The service provider details you are adding are completely private and are accessible only to you.</h4>
          </div>
        <div id="accordion-collapse" data-accordion="collapse" className="p-5">
        <h2 id="accordion-collapse-heading-1">
            <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
              <span>Services Details </span>
              <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
            <div class="p-5 border border-grey-700">
              <fieldset>

                {private_service_provider_profile.map((element) => {
                  return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                })
                }
              </fieldset>
            </div>
          </div>
          <h2 id="accordion-collapse-heading-2">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right border gap-3" data-accordion-target="accordion-collapse-body-2" onClick={handleAccordionClick}>
              <span>Address Details</span>
              <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
            <div class="p-5 border border-grey-700">
              <fieldset>

                {address_locales[selectedCountry]
                  ? address_locales[selectedCountry].map((element) => {
                    return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                  })
                  : address_locales["default"].map((element) => {
                    return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                  })}
              </fieldset>
            </div>
          </div>

        </div>
        <section className="flex flex-start gap-2 py-2">
          <button style={{ width: "fit-content", display: sent ? "none" : "block", }} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="col-span-2 px-2 button-style positive-action">
            ADD
          </button>

          {!sent ? (
            <div className="col-span-2">
              <button className="  button-style" onClick={()=>cancelFn()}>Cancel</button>
            </div>
          ) : (
            <div className="col-span-2">
              <button className=" button-style" onClick={()=>cancelFn()}>Close</button>
            </div>
          )}
          <div class=" col-span-8 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative w-full" role="alert" style={{ display: showAlert ? "block" : "none" }}>
          <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button><span class="text-sm">{alertText} </span>
          </div>
        </section>
        </div>
    </div>
  )
}
export default ServiceProviderModal