import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DeleteStream, GetStreamContent, GetStreams, GetStream, ConvertUnixTimestampToDateString, GetSharesForStream, GetServiceProvider,GetBeneficiary } from "../utilities/calls";
import { AiOutlineFileAdd } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";
import CopyToClipboard from "./CopyToClipboard";
import DeleteModal from "./DeleteModal";
import { displayDate, renderSharesWith, getSharesWith } from "../utilities/functions";
import { FaFileDownload,FaShareAlt } from "react-icons/fa";
import ShareModal from "./ShareModal";

export default function StreamList() {
    const [streams, setStreams] = useState([]);
    const [streamDetails, setStreamDetails] = useState({});
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const [currId, setCurrId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [sharedCounts, setSharedCounts] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedStream, setSelectedStream] = useState();
    const [sharesWith, setSharesWith] = useState({});
    const [spDetails, setSpDetails] = useState({});
    const [benDetails, setBenDetails] = useState({});
    let timer = undefined;
 
    const loadStreams = useCallback(() => {
        let referredSpIds = [];
        let referredBenIds = [];
    
        GetStreams().then(resp => {
            setStreams(resp)
            const fetchCounts = async (streams) => {
                const sharesPromises = streams.map((stream) =>
                  GetSharesForStream(stream.ID)
                        .then((response) => {
                            let sharesWithArr = getSharesWith(response, referredSpIds, referredBenIds)
                            referredBenIds.forEach(benId => GetBeneficiary(benId).then((el) => setBenDetails((benList) => ({...benList, [benId] : el}))).catch((err) => console.log(err)))
                            referredSpIds.forEach(spId => GetServiceProvider(spId).then((el) => setSpDetails((spList) => ({...spList, [spId] : el}))).catch((err) => console.log(err)))

                            return { streamId: stream.ID, count: response.length, sharesWith : sharesWithArr };
                        })
                    .catch((error) => {
                      console.error(`Error shares:`, error);
                      setShowAlert(true);
                      setAlertText("Error fetching shares");
                    })
                );
                Promise.all(sharesPromises)
                  .then((details) => {
                    const cmap = {};
                    const sharesMap = {};
                    details.forEach(
                      (detail) => { if(detail) { cmap[detail.streamId] = detail.count; sharesMap[detail.streamId] = detail.sharesWith } }
                    );
                    setSharedCounts(cmap);
                    console.log(sharesMap)
                    setSharesWith(sharesMap);
                  })
                  .catch((error) => {
                    console.error("Error setting record details:", error);
                    setShowAlert(true);
                    setAlertText("Error setting record details");
                  });
              };
              if (resp && resp.length > 0) {
                fetchCounts(resp);
              }
        }).catch(err => console.log('Error in fetch streams', err));
    },[]);
    useEffect(() => {
        loadStreams();
        return () => clearTimeout(timer);
    }, [loadStreams, timer])

  

    useEffect(() => {
        streams.forEach(stream => {
                GetStream(stream.ID).then(resp => {
                    setStreamDetails(prev => ({...prev,[stream.ID]: resp}));
                }).catch(err => console.log('Error getting stream details', err))
        })
    }, [streams]);

    const handleDeleteClick = (id) => {
        setCurrId(id);
        setConfirmationText("Are you certain you wish to delete this document?");
        setShowDeleteDlg(true);
    }

    const del = () => {
        setShowDeleteDlg(false);

        DeleteStream(currId).then(() => {
            setShowAlert(true);
            setAlertText("Deleted successfully");
            setStreams([]);
            loadStreams();
            timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        }).catch(err => {
            setAlertText("Error deleting the document.");
            setShowAlert(true);
            console.log('err', err)
        })
    }

    const handleDownload = (id) => {
        GetStream(id).then(stream => {
            GetStreamContent(id).then(resp => {
                const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
                const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
                // Create a temporary link element to download the file
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }).catch(err => {
                console.log('Error in downloading content', err);
                setAlertText("Error in downloading the document.");
                setShowAlert(true);
            })
        }).catch(err => {
            console.log('Error in downloading content', err);
            setAlertText("Error in downloading the document.");
            setShowAlert(true);
        })
    }

    const handleShareClick = (stream) => {
        setSelectedStream(stream);
        setShowShareModal(true);    
    }

    const showSharedWithModal = (streamid) => {
        var el = document.getElementById("sw_" + streamid);
        el.classList.remove("invisible");
    }

    const hideSharedWithModal = (streamid) => {
        document.getElementById("sw_" +streamid).classList.add('invisible')
    }

    return <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
        <h1 className="p-5 bold-text-input">{streams?.length} Documents</h1>
        <DeleteModal confirmationText={confirmationText} deleteLabel="Delete" onDeleteFn={del} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />
        <Link className="" to={{ pathname: "/documentdetails/" }} state={{}}
        >
            <button className="button-style hover:bg-555">
                <AiOutlineFileAdd className="inline-icon" />
                Add Document
            </button>
        </Link>
        <div
            class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
            role="alert"
            style={{ display: showAlert ? "block" : "none" }}
        >
            <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button><span class="text-sm">{alertText} </span>
        </div>
        <div class="w-full p-4 mt-2 border rounded-lg shadow border-gray-700">
            {streams && streams.length > 0 && streamDetails ?
                <ul class="w-full divide-y divide-gray-700">
                    {streams.map((stream, index) => {
                        return <li class="pt-1 pb-3 sm:pb-4">
                            <div class="flex justify-between items-center space-x-4 rtl:space-x-reverse">
                                <div class="flex-1">
                                    <Link class="text-base font-semibold text-blue-400" to={`/documentdetails/${stream?.ID}`}>
                                        {`${streamDetails[stream.ID]?.tags?.Name?.Value || "No Name"}`}</Link>
                                    {renderSharesWith("sw_", stream.ID, sharedCounts[stream.ID], sharesWith[stream.ID],showSharedWithModal, hideSharedWithModal, spDetails, benDetails)}
                                </div>
                                <div class="inline-flex items-center text-white">
                                    <button formMethod="dialog" formTarget="top" id="deleteTemplate" onClick={() => handleDeleteClick(stream.ID)} className="bg-slate-333 py-1 hover:bg-555">
                                        <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                    </button>
                                </div>
                            </div>
                            <p className="mt-1 text-xs text-gray-400">{streamDetails[stream?.ID]?.updated ? "Updated " + displayDate(ConvertUnixTimestampToDateString(streamDetails[stream.ID]?.updated)) : ""}  </p>

                            <p className="mt-1 text-sm text-gray-200 italic">{streamDetails[stream.ID]?.Description}</p>
                            <p class="mt-1 text-sm text-gray-400">
                                <span className="text-xs" id={"stream_" + index}>{stream.ID}</span><CopyToClipboard elId={"stream_" + index} />
                            </p>
                            <button formMethod="dialog" formTarget="top" id="sharecontent" onClick={() => handleShareClick(stream)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                                <FaShareAlt className="inline-icon" style={{ height: "1rem", width: "1rem" }} />Share
                            </button>
                            {streamDetails[stream.ID]?.tags?.__SYS_JW_FileName?.Value ?
                                <button formMethod="dialog" formTarget="top" id="download" onClick={() => handleDownload(stream.ID)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                                    <FaFileDownload className="inline-icon ms-2" style={{ height: "1rem", width: "1rem" }} />Download
                                </button>
                                : <></>}
                        </li>
                    })}
                </ul>
                
                : <span>No documents found</span>}
                  {showShareModal &&    <ShareModal stream={selectedStream} onClose={() => {loadStreams();setShowShareModal(false);}} title={`${streamDetails[selectedStream.ID]?.tags?.Name?.Value || ""}`}/>} 
        </div>
    </div>
}